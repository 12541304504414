import React from 'react'
import { graphql, StaticQuery } from 'gatsby'
import PropTypes from 'prop-types'
import Hobby from './Hobby'

class Hobbies extends React.Component {
  render() {
    const { data } = this.props
    const { edges: hobbies } = data.allMarkdownRemark

    return (
      <div className="p-5 is-experience">
        <h1 className="title is-5">Hobbies</h1>
        <hr />

        {hobbies &&
          hobbies.map(({ node: hobby }) => (
            <Hobby hobby={hobby} key={hobby.id} />
          ))}
      </div>
    )
  }
}

Hobbies.propTypes = {
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      edges: PropTypes.array,
    }),
  }),
}

export default () => (
  <StaticQuery
    query={graphql`
      query HobbiesQuery {
        allMarkdownRemark(
          filter: { frontmatter: { templateKey: { eq: "hobby" } } }
        ) {
          edges {
            node {
              id
              frontmatter {
                title
              }
            }
          }
        }
      }
    `}
    render={(data) => <Hobbies data={data} />}
  />
)
