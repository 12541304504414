import React from 'react'
import PropTypes from 'prop-types'

const Experience = ({ experience }) => {
  return (
    <div>
      <div className="columns">
        {experience.frontmatter.icon && (
          <div className="column is-1 is-narrow">
            <figure className="image mt-4" style={{ maxWidth: 64 }}>
              <img
                src={experience.frontmatter.icon.publicURL}
                alt={experience.frontmatter.icon.name}
              />
            </figure>
          </div>
        )}

        <div className="column is-11">
          <h1 className="title is-4">{experience.frontmatter.title}</h1>
          <h2 className="subtitle is-6">{experience.frontmatter.company}</h2>
          <h3 className="subtitle is-size-7 has-text-weight-light">
            {experience.frontmatter.startDate} -{' '}
            {experience.frontmatter.endDate === '' ||
            experience.frontmatter.endDate === 'Invalid date'
              ? 'Present'
              : experience.frontmatter.endDate}
          </h3>
          <div
            className="content is-small"
            dangerouslySetInnerHTML={{ __html: experience.html }}
          />
          <hr className="block" />
        </div>
      </div>
    </div>
  )
}

Experience.propTypes = {
  experience: PropTypes.shape({
    id: PropTypes.string,
    html: PropTypes.string,
    frontmatter: PropTypes.shape({
      title: PropTypes.string,
      icon: PropTypes.object,
      company: PropTypes.string,
      startDate: PropTypes.string,
      endDate: PropTypes.string,
    }),
  }).isRequired,
}

export default Experience
