import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import Layout from '../components/Layout'
import Experiences from '../components/experiences/Experiences'
import Qualifications from '../components/qualifications/Qualifications'
import Technologies from '../components/technologies/Technologies'
import Hobbies from '../components/hobbies/Hobbies'

export const DayPageTemplate = ({ title, description }) => {
  return (
    <section className="section">
      <div className="container">
        <div className="tile is-ancestor">
          <div className="tile is-parent is-8">
            <article className="tile is-child">
              <Experiences />
              <Qualifications />
            </article>
          </div>
          <div className="tile is-parent">
            <article className="tile is-child">
              <Technologies />
              <Hobbies />
            </article>
          </div>
        </div>
      </div>
    </section>
  )
}

DayPageTemplate.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
}

const DayPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark

  return (
    <Layout>
      <DayPageTemplate
        title={frontmatter.title}
        description={frontmatter.description}
      />
    </Layout>
  )
}

DayPage.propTypes = {
  data: PropTypes.object.isRequired,
}

export default DayPage

export const DayPageQuery = graphql`
  query DayPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        title
        description
      }
    }
  }
`
