import React from 'react'
import { graphql, StaticQuery } from 'gatsby'
import PropTypes from 'prop-types'
import Qualification from './Qualification'

class Qualifications extends React.Component {
  render() {
    const { data } = this.props
    const { edges: qualifications } = data.allMarkdownRemark

    return (
      <div className="box is-experience">
        <h1 className="title is-5">Qualification</h1>
        <hr />

        {qualifications &&
          qualifications.map(({ node: qualification }) => (
            <Qualification
              qualification={qualification}
              key={qualification.id}
            />
          ))}
      </div>
    )
  }
}

Qualifications.propTypes = {
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      edges: PropTypes.array,
    }),
  }),
}

export default () => (
  <StaticQuery
    query={graphql`
      query QualificationQuery {
        allMarkdownRemark(
          filter: { frontmatter: { templateKey: { eq: "qualification" } } }
          sort: { order: DESC, fields: frontmatter___endDate }
        ) {
          edges {
            node {
              id
              frontmatter {
                title
                description
                startDate(formatString: "MMMM, YYYY")
                endDate(formatString: "MMMM, YYYY")
              }
            }
          }
        }
      }
    `}
    render={(data) => <Qualifications data={data} />}
  />
)
