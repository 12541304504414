import React from 'react'
import PropTypes from 'prop-types'

const Qualification = ({ qualification }) => (
  <div>
    <h1 className="title is-5">{qualification.frontmatter.title}</h1>
    <h2 className="subtitle is-6">
      {qualification.frontmatter.startDate} -{' '}
      {qualification.frontmatter.endDate !== 'Invalid date'
        ? qualification.frontmatter.endDate
        : 'Present'}
    </h2>

    {qualification.frontmatter.description && (
      <h2 className="subtitle is-6 has-text-weight-light">
        {qualification.frontmatter.description}
      </h2>
    )}

    <div className="is-divider"></div>
  </div>
)

Qualification.propTypes = {
  qualification: PropTypes.shape({
    id: PropTypes.string,
    frontmatter: PropTypes.shape({
      title: PropTypes.string,
      description: PropTypes.string,
      startDate: PropTypes.string,
      endDate: PropTypes.string,
    }),
  }).isRequired,
}

export default Qualification
