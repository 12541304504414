import React from 'react'
import PropTypes from 'prop-types'

const Technology = ({ technologyGroup }) => (
  <div className="column is-12">
    <h1 className="title is-6 ">
      <u>{technologyGroup.fieldValue.split('_')[1]}</u>
    </h1>
    <div className="columns is-mobile is-multiline">
      {technologyGroup.edges &&
        technologyGroup.edges.map(({ node: technology }) => (
          <div key={technology.id} className="column is-narrow">
            <figure className="image is-48x48 is-inline-block">
              <img
                style={{
                  opacity: technology.frontmatter.current ? '1.0' : '0.3',
                }}
                src={technology.frontmatter.icon.publicURL}
                alt={technology.frontmatter.title}
              />
            </figure>
            <p
              className="is-size-7"
              style={{
                opacity: technology.frontmatter.current ? '1.0' : '0.5',
              }}
            >
              <span>&#8226; </span>
              {technology.frontmatter.title}
            </p>
          </div>
        ))}
    </div>
  </div>
)

Technology.propTypes = {
  technologyGroup: PropTypes.shape({
    fieldValue: PropTypes.string,
    edges: PropTypes.array,
  }).isRequired,
}

export default Technology
