import React from 'react'
import PropTypes from 'prop-types'

const Hobby = ({ hobby }) => (
  <div className="block">
    <h1 className="subtitle is-6">{hobby.frontmatter.title}</h1>
  </div>
)

Hobby.propTypes = {
  hobby: PropTypes.shape({
    id: PropTypes.string,
    frontmatter: PropTypes.shape({
      title: PropTypes.string,
    }),
  }).isRequired,
}

export default Hobby
