import React from 'react'
import { graphql, StaticQuery } from 'gatsby'
import PropTypes from 'prop-types'
import Experience from './Experience'

class Experiences extends React.Component {
  render() {
    const { data } = this.props
    const { edges: experiences } = data.allMarkdownRemark

    return (
      <div className="box is-experience">
        <h1 className="title is-5">Experience</h1>
        <hr />

        {experiences &&
          experiences.map(({ node: experience }) => (
            <Experience experience={experience} key={experience.id} />
          ))}
      </div>
    )
  }
}

Experiences.propTypes = {
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      edges: PropTypes.array,
    }),
  }),
}

export default () => (
  <StaticQuery
    query={graphql`
      query ExperienceQuery {
        allMarkdownRemark(
          filter: { frontmatter: { templateKey: { eq: "experience" } } }
          sort: { order: DESC, fields: frontmatter___startDate }
        ) {
          edges {
            node {
              id
              frontmatter {
                title
                icon {
                  publicURL
                  name
                }
                company
                startDate(formatString: "MMMM, YYYY")
                endDate(formatString: "MMMM, YYYY")
              }
              html
            }
          }
        }
      }
    `}
    render={(data) => <Experiences data={data} />}
  />
)
