import React from 'react'
import { graphql, StaticQuery } from 'gatsby'
import PropTypes from 'prop-types'
import Technology from './Technology'

class Technologies extends React.Component {
  render() {
    
    const { data } = this.props
    const { group: technologies } = data.allMarkdownRemark

    return (
      <div className="p-5 is-experience">
        <h1 className="title is-5">
          Technology <span className="subtitle is-size-7">&#8226; </span>
          <span className="subtitle is-size-7">
            Current stack is <strong>darker</strong>
          </span>
        </h1>

        <hr />

        {technologies &&
          technologies
            .reverse()
            .map((technologyGroup, index) => (
              <Technology
                technologyGroup={technologyGroup}
                key={technologyGroup.fieldValue + index}
              />
            ))}
      </div>
    )
  }
}

Technologies.propTypes = {
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      edges: PropTypes.array,
    }),
  }),
}

export default () => (
  <StaticQuery
    query={graphql`
      query TechnologyQuery {
        allMarkdownRemark(
          filter: { frontmatter: { templateKey: { eq: "technology" } } }
          sort: {
            fields: [frontmatter___type, frontmatter___current]
            order: [DESC, DESC]
          }
        ) {
          group(field: frontmatter___type) {
            fieldValue
            edges {
              node {
                id
                frontmatter {
                  current
                  title
                  icon {
                    publicURL
                  }
                }
              }
            }
          }
        }
      }
    `}
    render={(data) => <Technologies data={data} />}
  />
)
